var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", [
    _c("div", { staticClass: "error-text-container" }, [
      _c("h1", { staticClass: "not-found-title" }, [
        _vm._v("\n      404\n    "),
      ]),
      _c("p", { staticClass: "not-found-subtitle" }, [
        _vm._v(
          "\n      Uh-oh, traveler! You've wandered off the trail and into sasquatch territory.\n    "
        ),
      ]),
    ]),
    _c("div", { staticClass: "main-container mt-2" }, [
      _vm.$mq === "lg"
        ? _c("div", { staticClass: "game-container" }, [
            _c("p", { staticClass: "game-info-text" }, [
              _vm._v("\n        Maybe try a mighty jump over it?\n      "),
            ]),
            _c(
              "div",
              { staticClass: "game-area" },
              [
                _c("div", { staticClass: "score-container" }, [
                  _c("span", [
                    _vm._v(
                      "\n            Score: " +
                        _vm._s(_vm.formattedScore) +
                        "\n          "
                    ),
                  ]),
                  _c("div", { staticClass: "high-score-container" }, [
                    _c("span", [
                      _vm._v(
                        "\n              High Score: " +
                          _vm._s(_vm.formattedHighScore) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                !_vm.gameStarted || _vm.gameOver
                  ? _c("div", { staticClass: "start-overlay" }, [
                      _c("p", [
                        _vm._v("\n            Hit space to start\n          "),
                      ]),
                    ])
                  : _vm._e(),
                _c("b-img", {
                  staticClass: "player",
                  style: { color: "#6F6F6F", bottom: _vm.playerY + "px" },
                  attrs: {
                    alt: "player",
                    width: 100,
                    height: 100,
                    src: "/images/illustrations/404Page/sasquatch.svg",
                  },
                }),
                _vm._l(_vm.obstacles, function (obstacle, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("b-img", {
                        staticClass: "obstacle",
                        style: { left: obstacle.x + "px", bottom: "0px" },
                        attrs: {
                          id: "Layer_2",
                          alt: "tree",
                          height: 100,
                          src: obstacle.image,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c("div", { staticClass: "ground" }),
              ],
              2
            ),
          ])
        : _c(
            "div",
            { staticClass: "image-container" },
            [
              _c("b-img", {
                attrs: {
                  alt: "sasquatch",
                  src: "/images/illustrations/404Page/sasquatch.svg",
                },
              }),
            ],
            1
          ),
    ]),
    _c(
      "div",
      { staticClass: "back-button-container" },
      [
        _c(
          "b-button",
          {
            staticClass: "back-btn",
            attrs: { variant: "primary", "aria-label": "back button" },
            on: {
              click: function ($event) {
                return _vm.$router.push({ path: _vm.backButton.route })
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.backButton.label) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }