<template>
  <b-container>
    <div class="error-text-container">
      <h1 class="not-found-title">
        404
      </h1>
      <p class="not-found-subtitle">
        Uh-oh, traveler! You've wandered off the trail and into sasquatch territory.
      </p>
    </div>

    <div class="main-container mt-2">
      <div v-if="$mq === 'lg'" class="game-container">
        <p class="game-info-text">
          Maybe try a mighty jump over it?
        </p>
        <div class="game-area">
          <div class="score-container">
            <span>
              Score: {{ formattedScore }}
            </span>
            <div class="high-score-container">
              <span>
                High Score: {{ formattedHighScore }}
              </span>
            </div>
          </div>

          <div v-if="!gameStarted || gameOver" class="start-overlay">
            <p>
              Hit space to start
            </p>
          </div>

          <!-- Player -->
          <b-img
            alt="player"
            :style="{ color: '#6F6F6F', bottom: playerY + 'px' }"
            class="player"
            :width="100"
            :height="100"
            src="/images/illustrations/404Page/sasquatch.svg"
          />

          <!-- Obstacles -->
          <div v-for="(obstacle, index) in obstacles" :key="index">
            <b-img
              id="Layer_2"
              class="obstacle"
              alt="tree"
              :style="{ left: obstacle.x + 'px', bottom: '0px' }"
              :height="100"
              :src="obstacle.image"
            />
          </div>

          <div class="ground" />
        </div>
      </div>
      <div v-else class="image-container">
        <b-img
          alt="sasquatch"
          src="/images/illustrations/404Page/sasquatch.svg"
        />
      </div>
    </div>

    <div class="back-button-container">
      <b-button
        class="back-btn"
        variant="primary"
        aria-label="back button"
        @click="$router.push({ path: backButton.route })"
      >
        {{ backButton.label }}
      </b-button>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      isJumping: false,
      gameStarted: false,
      showMaxScoreMessage: false,
      obstacles: [],
      gameOver: false,
      score: 0,
      highScore: 0,
      playerY: 10,
      obstacleImages: [
        "/images/illustrations/404Page/tree1.svg",
        "/images/illustrations/404Page/tree2.svg",
      ],
      backButton: {
        label: '',
        route: '',
      },
    }
  },
  computed: {
    formattedScore() {
      return this.score.toString().padStart(4, '0')
    },
    formattedHighScore() {
      return this.highScore.toString().padStart(4, '0')
    },
  },

  mounted() {
    const currentRoute = this.$router.currentRoute.path

    // Custom Matomo Page View for Routes other than /404 so they can be tracked together
    if (window._paq && currentRoute !== '/404') {
      window._paq.push([
        'setDocumentTitle',
        `#/404 - ${currentRoute}`,
      ])
      window._paq.push(['trackPageView'])
    }

    this.setBackRoute()
    document.addEventListener('keydown', (event) => {
      if (event.code === 'Space' || event.code === 'ArrowUp') {
        if (!this.gameStarted) this.startGame()
        else if (!this.isJumping && this.playerY === 10) this.jump()
      }
    })
  },
  methods: {
    setBackRoute() {
      const previousRoute = this.$router.app?.previousRoute
      const companyId = previousRoute?.params?.companyId

      if ((previousRoute?.path.includes('stageline-v2') || previousRoute?.path.includes('stageline-v3')) && companyId) {
        this.backButton.route = `/stageline-v2/${companyId}/overview`
        this.backButton.label = 'Back to Overview'
      } else {
        this.backButton.route = '/'
        this.backButton.label = 'Back to Home'
      }
    },

    startGame() {
      if (this.gameStarted) return
      this.gameStarted = true
      this.gameOver = false
      this.obstacles = []
      this.score = 0

      this.gameInterval = setInterval(() => {
        if (this.gameOver) {
          clearInterval(this.gameInterval)
          clearInterval(this.scoreInterval)
          return
        }

        let speed = 5 + (Math.floor(this.score / 100) * 0.25)
        this.obstacles = this.obstacles.map(obs => ({ ...obs, x: obs.x - speed }))

        if (!this.obstacles.length || 800 - this.obstacles[this.obstacles.length - 1].x > this.randomGap()) {
          this.obstacles.push({
            x: 800,
            image: this.getRandomObstacleImage(),
          })
        }

        this.obstacles = this.obstacles.filter(obs => obs.x > -50)

        this.obstacles.forEach(obs => {
          const playerLeft = 50
          const playerRight = 50
          const obsLeft = obs.x - 50
          const obsRight = obs.x

          if (playerRight > obsLeft && playerLeft < obsRight && this.playerY <= 30) {
            this.gameOver = true
            this.gameStarted = false
            clearInterval(this.gameInterval)
            clearInterval(this.scoreInterval)
          }
        })

        if (this.score > this.highScore) this.highScore = this.score
      }, 30)

      this.scoreInterval = setInterval(() => {
        if (!this.gameOver) {
          if (this.score <  9999) this.score += 1
        }
      }, 100)
    },

    randomGap() {
      const MIN_OBSTACLE_GAP = 300
      const MAX_OBSTACLE_GAP = 600
      return Math.floor(Math.random() * (MAX_OBSTACLE_GAP - MIN_OBSTACLE_GAP + 1)) + MIN_OBSTACLE_GAP
    },

    getRandomObstacleImage() {
      return this.obstacleImages[Math.floor(Math.random() * this.obstacleImages.length)]
    },

    jump() {
      if (this.isJumping || this.gameOver) return
      this.isJumping = true

      let jumpHeight = 150
      let jumpSpeed = 10
      let gravity = 2

      let startY = 10
      let peakY = startY + jumpHeight
      let isRising = true

      let jumpInterval = setInterval(() => {
        if (isRising) {
          // Move upwards
          if (this.playerY >= peakY) {
            isRising = false
          } else {
            this.playerY += jumpSpeed
          }
        } else {
          // Move downwards (falling)
          if (this.playerY <= startY) {
            this.playerY = startY
            clearInterval(jumpInterval)
            this.isJumping = false
          } else {
            this.playerY -= gravity
            gravity += 0.5
          }
        }
      }, 16)
    },
  },
}
</script>


<style lang="scss" scoped>
.error-text-container {
  min-height: 25vh;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  line-height: 1;
  min-width: 100px;
  color: $ct-ui-background-2;
  font-weight: bolder;
}

.not-found-subtitle {
  font-size: 2rem;
  margin: 0;
}

.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.not-found-title {
  font-size: 8em !important;
  text-align: center;
}

.not-found-subtitle {
  font-size: 2em;
  text-align: center;
}

.main-container {
  display: block;
  width: 100%;
  margin: .5em auto;
}

.game-info-text {
  margin-bottom: 0;
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50vw;
    color: #6F6F6F;
  }
}

.score-container {
  text-align: right;
  font-size: 20px;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
}

.high-score-container {
  font-size: 18px;
  color: #999;
}

.game-area {
  position: relative;
  width: 100%;
  height: 250px;
  border-bottom: 2px solid #444;
  overflow: hidden;
}

.start-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.player {
  position: absolute;
  left: 50px;
  font-size: 40px;
  transition: bottom 0.2s ease-out;
}

.obstacle {
  position: absolute;
  font-size: 40px;
  color: green;
  bottom: 15px;
}

.ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: #444;
}

.restart-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.restart-button {
  background-color: #007bff;
  color: white;
  font-size: 20px;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
}

.restart-button:hover {
  background-color: #0056b3;
}

.back-btn {
  width: 400px
}

@media (max-width: 768px) {
  .not-found-title {
    margin-bottom: 1rem;
    font-size: 6em;
  }

  .not-found-subtitle {
    font-size: 1.5em;
  }

  .text-container {
    margin-left: 0;
    max-width: 100%;
  }

  .back-btn {
    width: 100%;
  }
}
</style>
